import { LPSData, LPSDataItem } from "./common";
import { getLPSType } from "./getLPSType";
import { checkClosed } from "./timeUtils";

const typeWeight = {
  general: 0,
  pediatrician: 1,
  dentist: 2,
  pharmacy: 3,
};

function subjectOpenHourToValue(subject: LPSDataItem): number {
  if (!checkClosed(subject.openDaily, subject.openWeekend)) return -1;
  else return 1;
}

function subjectNameToValue(subject: LPSDataItem): string {
  return subject.provider;
}

function subjectTypeToValue(subject: LPSDataItem): number {
  return typeWeight[getLPSType(subject.type)];
}

export const sortLPSData = (LPSData: LPSData): LPSData => {
  return LPSData.sort((a, b) => {
    const openHourDiff = subjectOpenHourToValue(a) - subjectOpenHourToValue(b);
    const nameDiff = subjectNameToValue(a).localeCompare(subjectNameToValue(b));
    const typeDiff = subjectTypeToValue(a) - subjectTypeToValue(b);

    if (openHourDiff !== 0) {
      return openHourDiff;
    } else if (nameDiff !== 0) {
      return nameDiff;
    } else {
      return typeDiff;
    }
  });
};
