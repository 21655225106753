import * as React from "react";
import { useContext, useEffect } from "react";

import Index from "../components/Index";
import StoreContext from "../context/StoreContext";

function IndexPage(): JSX.Element {
  const { updateChecked } = useContext(StoreContext);

  useEffect(() => {
    const url = new URL(window.location.href);
    const tags = url.searchParams.get("tags");

    if (tags) {
      if (tags.includes(",")) {
        updateChecked(tags.split(","));
      } else {
        updateChecked([tags]);
      }
    }
  }, []);

  return <Index />;
}

export default IndexPage;
