import { Stack, Typography } from "@mui/material";
import { SxProps } from "@mui/system";
import * as React from "react";

import LPSIconLabelsQuery from "../staticQueries/LPSIconLabelsQuery";
import { LPS } from "../utils/common";
import LPSIcon from "./LPSIcon";

interface Props {
  fill: string;
  type: LPS;
  width: string;
  height: string;
  stackSx?: SxProps;
}

function LabeledIcon({
  fill,
  type,
  width,
  height,
  stackSx,
}: Props): JSX.Element {
  const { LPSIconLabels } = LPSIconLabelsQuery();

  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={0}
      sx={{ ...stackSx }}
    >
      <LPSIcon fill={fill} type={type} width={width} height={height} />
      <Typography color={fill}>{LPSIconLabels[type]}</Typography>
    </Stack>
  );
}

export default LabeledIcon;
