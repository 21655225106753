import { graphql, useStaticQuery } from "gatsby";

import { MapTranslationQuery } from "../graphqlTypes";
import useLocalization from "../hooks/useLocalization";

const mapTranslationQuery =
  (): MapTranslationQuery["allLocalizableJson"]["edges"][number]["node"]["map"] => {
    const { iso } = useLocalization();

    const { allLocalizableJson } = useStaticQuery<MapTranslationQuery>(graphql`
      query MapTranslation {
        allLocalizableJson {
          edges {
            node {
              language
              map {
                searchBoxPlaceholder
                viewList
                viewMap
              }
            }
          }
        }
      }
    `);

    return allLocalizableJson.edges
      .filter((translation) => translation.node.language === iso)
      .map((translation) => translation.node.map)[0];
  };

export default mapTranslationQuery;
