import { Stack, useMediaQuery, useTheme } from "@mui/material";
import * as React from "react";
import { useContext } from "react";

import StoreContext from "../context/StoreContext";
import { LPS } from "../utils/common";
import { LPSTypes } from "../utils/constants";
import Tag from "./Tag";

function TagFilter(): JSX.Element {
  const { toggleChecked } = useContext(StoreContext);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      width={isMobile ? "90vw" : "376px"}
      spacing={1}
    >
      {LPSTypes.map((item: LPS, key) => (
        <Tag key={key} type={item} updateChecked={toggleChecked} />
      ))}
    </Stack>
  );
}

export default TagFilter;
