import { List, ListItem, useMediaQuery, useTheme } from "@mui/material";
import * as React from "react";

import LPSListTranslationQuery from "../staticQueries/LPSListTranslationQuery";
import { LPSDataProps } from "../utils/common";
import { getLPSType } from "../utils/getLPSType";
import { sortLPSData } from "../utils/sortLPSData";
import { checkClosed, checkNonstop, getOpeningHours } from "../utils/timeUtils";
import LPSListItem from "./LPSListItem";

function LPSList({ LPSData }: LPSDataProps): JSX.Element {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const sortedLPSData = sortLPSData(LPSData);
  const noEmergenciesFound = LPSListTranslationQuery();

  const listItems = sortedLPSData.map((item, key) => {
    return (
      <LPSListItem
        key={key}
        id={item.id}
        openingHours={getOpeningHours(item.openDaily, item.openWeekend)}
        address={item.address.replace(/["]/g, "")}
        label={item.provider}
        type={getLPSType(item.type)}
        closed={checkClosed(item.openDaily, item.openWeekend)}
        distanceCar={item.distanceCar}
        nonstop={checkNonstop(item.openDaily, item.openWeekend)}
        altBg={key % 2 === 1}
        divider
      />
    );
  });

  // TODO
  const sxList = isMobile
    ? {
        position: "absolute",
        bottom: 0,
        width: "100%",
        height: "77%",
        overflow: "auto",
        borderRadius: "15px 15px 0 0",
        backgroundColor: "background.paper",
      }
    : {
        overflowY: "scroll",
        borderRadius: "15px 15px 15px 15px",
        backgroundColor: "background.paper",
      };

  return (
    <List
      sx={{
        ...sxList,
        "&::-webkit-scrollbar": {
          display: "none",
        },
        scrollbarWidth: "none",
      }}
    >
      {listItems.length > 0 ? (
        listItems
      ) : (
        <ListItem
          sx={{
            width: "376px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {noEmergenciesFound}
        </ListItem>
      )}
    </List>
  );
}

export default LPSList;
