import "mapbox-gl/dist/mapbox-gl.css";

import { Stack, useMediaQuery, useTheme } from "@mui/material";
import { Button, IconButton } from "gatsby-theme-material-ui";
import * as React from "react";
import { useContext } from "react";

import MapContext from "../context/MapContext";
import StoreContext from "../context/StoreContext";
import mapTranslationQuery from "../staticQueries/mapTranslationQuery";
import Controls from "./Controls";
import GoBackButton from "./GoBackButton";
import SatelliteIcon from "./icons/SatelliteIcon";
import LPSList from "./LPSList";
import Map from "./Map";
import SearchBox from "./SearchBox";
import TagFilter from "./TagFilter";

function Index(): JSX.Element {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isTabletOrDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const { viewMap, viewList } = mapTranslationQuery();

  const { listOpen, toggleList, filteredListData } = useContext(StoreContext);
  const { toggleSatellite } = useContext(MapContext);

  const handleShowList = (): void => {
    toggleList();
  };

  const controls = (
    <>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
        spacing={1}
      >
        <GoBackButton link="/roadmap" />
        <SearchBox />
      </Stack>
      <TagFilter />
    </>
  );

  return (
    <>
      {isMobile && (
        <div>
          <Map />
          <Controls>{controls}</Controls>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={0}
          >
            <Button
              variant="contained"
              color="secondary"
              onClick={handleShowList}
              sx={{
                position: "absolute",
                bottom: 20,
                zIndex: 1,
                width: "178px",
              }}
            >
              {listOpen ? viewMap : viewList}
            </Button>
            {listOpen && <LPSList LPSData={filteredListData} />}
          </Stack>
          <IconButton
            sx={{
              position: "absolute",
              bottom: 15,
              left: 5,
              zIndex: 1,
            }}
            onClick={toggleSatellite}
          >
            <SatelliteIcon />
          </IconButton>
        </div>
      )}
      {isTabletOrDesktop && (
        <>
          {controls}
          <LPSList LPSData={filteredListData} />
        </>
      )}
    </>
  );
}

export default Index;
