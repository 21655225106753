import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import { ListItem, ListItemButton, Stack, Typography } from "@mui/material";
import { Link } from "gatsby-theme-material-ui";
import * as React from "react";

import { LPS } from "../utils/common";
import LabeledIcon from "./LabeledIcon";
import OpenInfo from "./OpenInfo";

interface Props {
  id: string;
  type: LPS;
  label: string;
  address: string;
  divider: boolean;
  distanceCar?: number;
  openingHours: string;
  closed?: boolean;
  nonstop?: boolean;
  altBg?: boolean;
}

function LPSListItem({
  id,
  type,
  label,
  distanceCar,
  address,
  openingHours,
  closed,
  nonstop,
  altBg,
  divider,
}: Props): JSX.Element {
  const fill = closed ? "#A39E93F2" : "#E66A6A";
  const textColor = closed ? "text.disabled" : "text.primary";

  return (
    <ListItem
      divider={divider}
      sx={{
        bgcolor: altBg && "background.listItem",
      }}
      disablePadding
    >
      <ListItemButton>
        <Link to={`/detail/${id}`} underline="none">
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={1}
            sx={{
              padding: 2,
            }}
          >
            <LabeledIcon
              type={type}
              height="32"
              width="31"
              fill={fill}
              stackSx={{
                width: "81px",
              }}
            />
            <Stack justifyContent="center" alignItems="flex-start">
              <Typography
                color="text.primary"
                variant="h3"
                fontSize="16px"
                maxWidth="240px"
              >
                {label}
              </Typography>
              <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={1}
              >
                <Typography color={textColor} fontSize="12px">
                  {address}
                </Typography>
              </Stack>
              <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={1}
              >
                <OpenInfo
                  nonstop={nonstop}
                  closed={closed}
                  openingHours={openingHours}
                />
                {distanceCar && (
                  <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={1}
                  >
                    <Stack
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      spacing={0.5}
                    >
                      <DirectionsCarIcon
                        sx={{
                          fontSize: "12px",
                          color: textColor,
                        }}
                      />
                      <Typography color={textColor} fontSize="12px">
                        {Math.round(distanceCar)} min
                      </Typography>
                    </Stack>
                  </Stack>
                )}
              </Stack>
            </Stack>
          </Stack>
        </Link>
      </ListItemButton>
    </ListItem>
  );
}

export default LPSListItem;
