import SearchIcon from "@mui/icons-material/Search";
import { Input, Stack, useMediaQuery, useTheme } from "@mui/material";
import { IconButton } from "gatsby-theme-material-ui";
import * as React from "react";
import { FormEvent, useContext } from "react";

import StoreContext from "../context/StoreContext";
import mapTranslationQuery from "../staticQueries/mapTranslationQuery";

function SearchBox(): JSX.Element {
  const { searchBoxPlaceholder } = mapTranslationQuery();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const { searchValue, updateSearchValue, filterData, openList } =
    useContext(StoreContext);

  const handleChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    updateSearchValue(event.target.value);
  };

  const handleSearchBoxClick = () => {
    openList();
  };

  const handleSearch = () => {
    filterData();
  };

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    handleSearch();
  };

  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={1}
    >
      <form onSubmit={handleSubmit}>
        <Input
          onClick={handleSearchBoxClick}
          placeholder={searchBoxPlaceholder}
          value={searchValue}
          onChange={handleChange}
          sx={{
            backgroundColor: "background.paper",
            width: isMobile ? "85vw" : "328px",
            height: "40px",
          }}
          endAdornment={
            <IconButton onClick={handleSearch}>
              <SearchIcon />
            </IconButton>
          }
        />
      </form>
    </Stack>
  );
}

export default SearchBox;
