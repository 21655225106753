import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import { Checkbox, Chip, Typography } from "@mui/material";
import * as React from "react";
import { useContext } from "react";

import StoreContext from "../context/StoreContext";
import LPSIconLabelsQuery from "../staticQueries/LPSIconLabelsQuery";
import { LPS } from "../utils/common";

interface Props {
  type: LPS;
  updateChecked: (type: LPS) => void;
}

function Tag({ type, updateChecked }: Props): JSX.Element {
  const { LPSIconLabels, language } = LPSIconLabelsQuery();
  const { checked } = useContext(StoreContext);

  return (
    <Chip
      icon={
        <Checkbox
          checked={checked[type]}
          icon={
            <CircleOutlinedIcon
              sx={{
                width: "20px",
                height: "20px",
              }}
            />
          }
          checkedIcon={
            <CheckCircleIcon
              sx={{
                width: "20px",
                height: "20px",
              }}
            />
          }
          sx={{
            width: "20px",
            height: "20px",
          }}
        />
      }
      label={
        <Typography fontSize={language === "en" ? "10px" : "12px"}>
          {LPSIconLabels[type]}
        </Typography>
      }
      onClick={() => updateChecked(type)}
      color="success"
      sx={{
        bgcolor: !checked[type] && "background.default",
      }}
    />
  );
}

export default Tag;
