import { graphql, useStaticQuery } from "gatsby";

import { LpsListTranslationQuery } from "../graphqlTypes";
import useLocalization from "../hooks/useLocalization";

const LPSListTranslationQuery =
  (): LpsListTranslationQuery["allLocalizableJson"]["edges"][number]["node"]["noEmergenciesFound"] => {
    const { iso } = useLocalization();

    const { allLocalizableJson } =
      useStaticQuery<LpsListTranslationQuery>(graphql`
        query LPSListTranslation {
          allLocalizableJson {
            edges {
              node {
                language
                noEmergenciesFound
              }
            }
          }
        }
      `);

    return allLocalizableJson.edges
      .filter((translation) => translation.node.language === iso)
      .map((translation) => translation.node.noEmergenciesFound)[0];
  };

export default LPSListTranslationQuery;
